function getUrlVars() {
  var vars = {}, hash;
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars[hash[0]] = hash[1];
  }
  return vars;
}

document.addEventListener("DOMContentLoaded", function (e) {
  var layer = document.getElementById('layer');
  window.setTimeout(function () {
    new daum.Postcode({
      oncomplete: function (data) {
        var fullRoadAddr = data.roadAddress; // 도로명 주소 변수
        var extraRoadAddr = ''; // 도로명 조합형 주소 변수

        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
          extraRoadAddr += data.bname;
        }
        // 건물명이 있고, 공동주택일 경우 추가한다.
        if (data.buildingName !== '' && data.apartment === 'Y') {
          extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
        }
        // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
        if (extraRoadAddr !== '') {
          extraRoadAddr = ' (' + extraRoadAddr + ')';
        }
        // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
        if (fullRoadAddr !== '') {
          fullRoadAddr += extraRoadAddr;
        }

        var mVars = getUrlVars();

        var param = mVars['platform'];

        if (param === "aos" || param === "android") {
          window.Ddocdoc.successAddressData(data.zonecode, fullRoadAddr, data.jibunAddress, data.bcode);
        } else if (param === "ios") {
          const addressData = {
            action: "successAddressData",
            data: {
              zonecode: data.zonecode,
              fullRoadAddr: fullRoadAddr,
              jibunAddress: data.jibunAddress,
              bcode: data.bcode
            }
          };
          window.webkit.messageHandlers.Ddocdoc.postMessage(addressData);
        } else {
          const addressData = {
            action: "successAddressData",
            data: {
              zonecode: data.zonecode,
              fullRoadAddr: fullRoadAddr,
              jibunAddress: data.jibunAddress,
              bcode: data.bcode
            }
          };
          window.opener.postMessage(addressData, '*');
          window.close();
        }
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
        // 예제를 참고하여 다양한 활용법을 확인해 보세요.
      },
      width: '100%',
      height: '100%',
    }).embed(layer);
  }, 100);
});
